@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

:root {
  --orange-main: #ffa05c;
  --whatsapp: #00a884;
  --email-gradient: linear-gradient(229.51deg, #1d74f2 17.39%, #1ac3fb 85.67%);
  --profile-gradient: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 160, 92, 1) 100%);
  --newtech-bg: #17a38c;
  --mr-bg: #072736;
  --alorica-bg: #f58025;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-web-dev {
  background-color: #ff7e36 !important;
}

.bg-ux-ui {
  background-color: #018f8c !important;
}

.bg-graphic-design {
  background-color: #f9bf51 !important;
}

.job-info-text {
  margin: 0;
}

.gh-body {
  font: 400 16px/1.5 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #111;
  background-color: #fdfdfd;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: 'kern' 1;
  -moz-font-feature-settings: 'kern' 1;
  -o-font-feature-settings: 'kern' 1;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  padding: 30px;
  border: 1px solid #e1e4e8;
  border-radius: 2px;
}

.gh-body a {
  color: #0366d6;
  text-decoration: none;
}

.gh-body a:visited {
  color: #0366d6;
}

.gh-body a:hover {
  color: #0366d6;
  text-decoration: underline;
}

.gh-body h1 {
  border-bottom: 1px solid #eaecef;
  color: #111;
  /* Darker */
}

@media only screen and (max-width: 991px) {
  .card {
    margin: auto;
  }

  .custom-card {
    flex-direction: column !important;
    align-items: center;
  }

  .company-profile-pic {
    margin-bottom: 1rem;
  }

  .job-info {
    padding: 0 !important;
    text-align: center;
  }

  .job-info-row {
    flex-direction: column !important;
    align-items: center;
  }

  .job-info-text {
    margin-bottom: 1rem !important;
  }
}
